import { defaultChainId } from 'components/DummyData';
import {
    TOGGLE_WALLET_LOADING_POPUP,
    GET_WALLET_INFO,
    SET_HAS_TRIED_TO_CONNECT,
    GET_WEB3_CONNECTED,
    GET_USER_BALANCES_LOADER,
    GET_USER_BALANCES_SUCCESS,
    GET_USER_BALANCES_ERROR,
    GET_WALLET_ADDRESS_LOADER,
    GET_WALLET_ADDRESS_SUCCESS,
    SET_CURRENT_CHAIN_ID,
    SET_TRANSACTION_LOADER,
    GET_CONNECTED_ADDRESS,
    GET_FAUCET_TOKEN,
    GET_PROFILE_TOKEN,
    GET_PROFILE1_SUCCESS,
    GET_PROFILE2_SUCCESS,
    GET_PROFILE_ERROR,
    FETCH_POOLS,
    FETCH_POOLS_SUCCESS,
    FETCH_POOLS_RAW_SUCCESS,
    FETCH_POOLS_ERROR,
    GET_SLIPPAGE,
    GET_RECENT_TX,
    SET_CALCULATED_TRANSACTIONS,
    SET_TOTAL_POOLS,
    SET_TRADE_LOAD_STATUS,
    GET_PROFILE_CHAINS,
    GET_PROFILE_CHAINS_SUCCESS,
    GET_PROFILE_CHAINS_ERROR,
    GET_OTHER_SWAP_OPTIONS,
    GET_PROFILE_SELECTED_CHAINS,
} from './actionsTypes';
import cachedChains from 'constants/chains.json';

const initialStates = {
    showWalletLoadingPopup: false,
    hasTriedToConnect: false,
    walletInfo: {},
    web3Connected: false,
    walletAddressLoader: false,
    walletAddressData: [],
    isShowWalletConnect: false,
    netWorkSupported: '',
    transactionLoader: false,
    connectedAddress: '',
    FaucetToken: '',
    ProfileToken1: [],
    ProfileToken2: [],
    userBalances: {},
    userBalancesLoader: true,
    PoolsListMapping: {},
    RawPools: [],
    txSetting: '',
    VirtuSwapRouter: false,
    MultiHops: false,
    RecentTx: false,
    calculatedTransactions: [],
    poolLoader: true,
    totalPools: [],
    isTradeLoad: {
        status: false,
        id: 0,
    },
    apiStatusTrade: [],
    currentChainId: Number(localStorage?.getItem('lastChainId')) || null,
    ChainListUpdated: false,
    ChainList: cachedChains || [{ id: defaultChainId }],
    ChainListLoader: true,
    SwapOptionsList: [],
    SwapOptionsAllLoaded: false,
    SwapOptionsListLoader: true,
    selectedChain: { chainId1: { id: defaultChainId }, chainId2: { id: defaultChainId }, part: 1 },
    ProfileTokenLoader: true,
};

export const TradeReducer = (state = initialStates, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_TOTAL_POOLS:
            return {
                ...state,
                totalPools: payload,
            };
        case SET_CALCULATED_TRANSACTIONS:
            return {
                ...state,
                calculatedTransactions: payload,
            };

        case TOGGLE_WALLET_LOADING_POPUP:
            return {
                ...state,
                showWalletLoadingPopup: payload,
            };
        case GET_WALLET_INFO:
            return {
                ...state,
                walletInfo: payload,
            };
        case SET_HAS_TRIED_TO_CONNECT:
            return {
                ...state,
                hasTriedToConnect: payload,
            };
        case GET_WEB3_CONNECTED:
            return {
                ...state,
                web3Connected: payload,
            };

        case GET_WALLET_ADDRESS_LOADER:
            return {
                ...state,
                walletAddressLoader: payload,
            };

        case GET_WALLET_ADDRESS_SUCCESS:
            return {
                ...state,
                walletAddressData: payload,
            };

        //Set Current Chain Id
        case SET_CURRENT_CHAIN_ID:
            return {
                ...state,
                currentChainId: payload,
                netWorkSupported: state.ChainList.some(chain => !!chain.vrouter_address && chain.id === payload),
            };

        case SET_TRANSACTION_LOADER:
            return {
                ...state,
                transactionLoader: payload,
            };

        case GET_CONNECTED_ADDRESS:
            return {
                ...state,
                connectedAddress: payload,
            };

        //Faucet Page
        case GET_FAUCET_TOKEN:
            return {
                ...state,
                FaucetToken: payload,
            };

        //Get User Balances
        case GET_USER_BALANCES_LOADER:
            return {
              ...state,
              userBalancesLoader: true,
            };
        case GET_USER_BALANCES_SUCCESS:
            return {
                ...state,
                userBalancesLoader: false,
                userBalances: {
                    ...state.userBalances,
                    ...payload,
                },
            };
        case GET_USER_BALANCES_ERROR:
            return {
                ...state,
                userBalancesLoader: false,
            };

        //get Profile Token
        case GET_PROFILE_TOKEN:
            if (payload?.isRefresh) {
                if (!payload?.switch) {
                    if (payload?.part === 1) {
                        return {
                            ...state,
                            ProfileToken1: [],
                            ProfileTokenLoader: payload?.loader,
                        };
                    } else if (payload?.part === 2) {
                        return {
                            ...state,
                            ProfileToken2: [],
                            ProfileTokenLoader: payload?.loader,
                        };
                    } else {
                        return {
                            ...state,
                            ProfileToken1: [],
                            ProfileToken2: [],
                            ProfileTokenLoader: payload?.loader,
                        };
                    }
                } else {
                    return {
                        ...state,
                        ProfileToken1: state.ProfileToken2,
                        ProfileToken2: state.ProfileToken1,
                        ProfileTokenLoader: payload?.loader,
                    };
                }
            } else {
                return {
                    ...state,
                    ProfileTokenLoader: payload?.loader,
                };
            }
        case GET_PROFILE1_SUCCESS:
            return {
                ...state,
                ProfileToken1: payload?.map((profileToken, index) => ({
                    ...profileToken,
                    id: index + 1 + profileToken?.name,
                })),
                apiStatusTrade: state.apiStatusTrade.filter(({ type }) => type !== 'profile'),
                ProfileTokenLoader: false,
            };
        case GET_PROFILE2_SUCCESS:
            return {
                ...state,
                ProfileToken2: payload?.map((profileToken, index) => ({
                    ...profileToken,
                    id: index + 1 + profileToken?.name,
                })),
                apiStatusTrade: state.apiStatusTrade.filter(({ type }) => type !== 'profile'),
                ProfileTokenLoader: false,
            };
        case GET_PROFILE_ERROR:
            return {
                ...state,
                apiStatusTrade: [...state.apiStatusTrade, { type: 'profile', payload }],
            };

        //Fetch Pools
        case FETCH_POOLS:
            return {
                ...state,
                poolLoader: true,
            };
        case FETCH_POOLS_SUCCESS:
            return {
                ...state,
                PoolsListMapping: {
                    ...state.PoolsListMapping,
                    ...payload,
                },
                apiStatusTrade: state.apiStatusTrade.filter(({ type }) => type !== 'pools'),
                poolLoader: false,
            };
        case FETCH_POOLS_RAW_SUCCESS:
            return {
                ...state,
                RawPools: payload,
            };
        case FETCH_POOLS_ERROR:
            return {
                ...state,
                poolLoader: false,
                apiStatusTrade: [...state.apiStatusTrade, { type: 'pools', payload }],
            };

        //Get Slippage tolerance
        case GET_SLIPPAGE:
            return {
                ...state,
                txSetting: payload,
            };

        //Get Recent Transactions
        case GET_RECENT_TX:
            return {
                ...state,
                RecentTx: payload,
            };

        //Set Trade Load Status
        case SET_TRADE_LOAD_STATUS:
            return {
                ...state,
                isTradeLoad: payload,
            };

        //get Profile Chains
        case GET_PROFILE_CHAINS:
            return {
                ...state,
                ChainListLoader: true,
            };
        case GET_PROFILE_CHAINS_SUCCESS:
            return {
                ...state,
                ChainList: payload,
                ChainListUpdated: true,
                netWorkSupported: payload.some(chain => !!chain.vrouter_address && chain.id === state.currentChainId),
                ChainListLoader: false,
            };
        case GET_PROFILE_CHAINS_ERROR:
            return {
                ...state,
                ChainListLoader: false,
            };
        //Other swap options
        case GET_OTHER_SWAP_OPTIONS:
            return {
                ...state,
                SwapOptionsList: payload?.formattedCrossChainOptions ?? state.SwapOptionsList,
                SwapOptionsAllLoaded: payload?.allLoaded ?? state.SwapOptionsAllLoaded,
                SwapOptionsListLoader: payload?.loader ?? state.SwapOptionsListLoader,
            };
        //get Profile Selected Chain
        case GET_PROFILE_SELECTED_CHAINS:
            return {
                ...state,
                selectedChain: payload,
            };
        default:
            return state;
    }
};

export default TradeReducer;
