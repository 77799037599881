import ERC20ABI from '../constants/ERC20.json';
import vRouterABI from '../constants/vRouter.json';
import vPairABI from '../constants/vPair.json';
import stakerABI from '../constants/vStaker.json';
import WrappedNativeABI from '../constants/IWrappedNative.json';
import Multicall3ABI from '../constants/IMulticall3.json';
import { chainInfo } from '@virtuswap/v1-sdk';
import { constants, ethers } from 'ethers';
import { Interface } from '@ethersproject/abi';
import { Decimal } from 'decimal.js';
import _ from 'lodash';

Decimal.set({ precision: 30 });

const SECS_IN_WEEK = 604800;
const multicall3DefaultBatchSize = 500;

export default class VirtuSwapService {
    static storedWalletProvider = Promise.resolve(null);
    static cachedRpcUrl = '';
    static vRouterAddress = '';
    static vrswStakerAddress = '';
    static vrswAddress = '';
    static gvrswAddress = '';
    static multicall3Address = '0xcA11bde05977b3631167028862bE2a173976CA11'; // see https://www.multicall3.com/
    static wrappedNativeCurrencyCache = {};

    static setHookValue = (walletProvider) => {
        this.storedWalletProvider = walletProvider;
    };

    static setAddressesFromChainInfo = (chain) => {
        this.cachedRpcUrl = chain.rpc_url;
        this.vRouterAddress = chainInfo[chain.id]?.router3Address ?? chainInfo[chain.id]?.router2Address;
        this.vrswStakerAddress = chain.vstaker_address;
        this.vrswAddress = chain.vrsw_token_address;
        this.gvrswAddress = chain.vevrsw_token_address;
        this.wrappedNativeCurrencyCache[this.vRouterAddress] = chainInfo[chain.id]?.weth9Address;
    };

    static getProvider = async () => {
        const walletProvider = await this.storedWalletProvider;
        if (walletProvider) {
            return walletProvider;
        } else if (window.ethereum) {
            return new ethers.providers.Web3Provider(window.ethereum);
        }
    };

    static getSignerAddress = async () => {
        const provider = await this.getProvider();
        const signer = provider.getSigner();
        const address = await signer.getAddress();
        return address;
    };

    static sendEncoded = async (encodedTx) => {
        const provider = await this.getProvider();
        let tx = await provider.getSigner().sendTransaction(encodedTx);
        await tx.wait();
    };

    static getNetwork = async () => {
        const provider = await this.getProvider();
        return await provider.getNetwork();
    };

    static getCurrentChainId = async () => {
        const networkInfo = await this.getNetwork();
        return networkInfo.chainId;
    };

    static getLastBlockTs = async () => {
        const provider = await this.getProvider();
        return (await provider.getBlock('latest')).timestamp;
    };

    static getvRouter = async () => {
        const provider = await this.getProvider();
        const signer = provider.getSigner();
        const vRouter = new ethers.Contract(
            this.vRouterAddress,
            vRouterABI,
            provider
        );
        vRouter.connect(signer);
        return vRouter;
    };

    static getvStaker = async () => {
        const provider = await this.getProvider();
        const signer = provider.getSigner();
        const stakerInstance = new ethers.Contract(
            this.vrswStakerAddress,
            stakerABI,
            provider,
            signer
        ).connect(signer);
        return stakerInstance;
    };

    static getMaxVirtualTradeAmountRtoN = async (jk, ik) => {
        const provider = await this.getProvider();
        const signer = provider.getSigner();
        const vRouter = new ethers.Contract(
            this.vRouterAddress,
            vRouterABI,
            provider
        );
        vRouter.connect(signer);

        let maxAmount = await vRouter.getMaxVirtualTradeAmountRtoN(jk, ik);
        return maxAmount;
    };

    static getPoolCurrentReserveRatio = async (poolAddress) => {
        const provider = await this.getProvider();
        const signer = provider.getSigner();
        const vPair = new ethers.Contract(poolAddress, vPairABI, provider);
        vPair.connect(signer);

        let currentRR = await vPair.calculateReserveRatio();
        let maxRR = await vPair.maxReserveRatio();
        return { currentRR, maxRR };
    };

    static scanLPTokens = async (pools) => {
        /*
        let lpTokens = [];

        const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

        for (let i = 0; i < pools.length; i++) {
            if (pools[i].poolAddress === ethers.constants.AddressZero) continue;
            try {
                let balance = await this.getERC20Balance(pools[i].poolAddress);
                let adjustedBalance = parseFloat(
                    ethers.utils.formatUnits(balance, 18)
                );
                lpTokens.push({
                    poolAddress: pools[i].poolAddress,
                    balance: adjustedBalance,
                });

                await delay(500);
            } catch (ex) {
                console.log(
                    'failed to fetch balance for pool: ' +
                        pools[i].poolAddress +
                        ' trying again after 2s sleep'
                );
                //try again after some delay
                await delay(2000);
                try {
                    let balance = await this.getERC20Balance(
                        pools[i].poolAddress
                    );
                    let adjustedBalance = parseFloat(
                        ethers.utils.formatUnits(balance, 18)
                    );
                    lpTokens.push({
                        poolAddress: pools[i].poolAddress,
                        balance: adjustedBalance,
                    });
                } catch {
                    console.log(
                        'failed to fetch balance for pool: ' +
                            pools[i].poolAddress
                    );
                }
            }
        }
        */

        return [];
    };

    static getTokenTotalSupply = async (tokenAddress) => {
        const provider = await this.getProvider();
        const signer = provider.getSigner();
        // const signerAddress = await signer.getAddress();
        const erc20Instance = new ethers.Contract(
            tokenAddress,
            ERC20ABI,
            provider,
            signer
        ).connect(signer);

        return await erc20Instance.totalSupply();
    };

    static getWrappedNativeAddress = async () => {
        if (!this.wrappedNativeCurrencyCache[this.vRouterAddress]) {
            const vRouter = await this.getvRouter();
            this.wrappedNativeCurrencyCache[this.vRouterAddress] = await vRouter.WETH9();
        }
        return this.wrappedNativeCurrencyCache[this.vRouterAddress];
    };

    static wrapNativeCurrency = async (amount) => {
        const provider = await this.getProvider();
        const signer = provider.getSigner();

        const wrappedNativeAddress = await this.getWrappedNativeAddress();

        const wrapperInstance = new ethers.Contract(
            wrappedNativeAddress,
            WrappedNativeABI,
            signer
        );

        const gasLimit = (await wrapperInstance.estimateGas.deposit({ value: amount })).mul(11).div(10);
        let tx = await wrapperInstance.deposit({ value: amount, gasLimit });
        await tx.wait();
        return tx.hash;
    };

    static unwrapNativeCurrency = async (amount) => {
        const provider = await this.getProvider();
        const signer = provider.getSigner();

        const wrappedNativeAddress = await this.getWrappedNativeAddress();

        const wrapperInstance = new ethers.Contract(
            wrappedNativeAddress,
            WrappedNativeABI,
            signer
        );

        const gasLimit = (await wrapperInstance.estimateGas.withdraw(amount)).mul(11).div(10);
        let tx = await wrapperInstance.withdraw(amount, { gasLimit });
        await tx.wait();
        return tx.hash;
    };

    static getBalance = async (network) => {
        const provider = network ?
            ethers.getDefaultProvider(network) :
            await this.getProvider();
        const signer = (await this.getProvider()).getSigner();
        const signerAddress = await signer.getAddress();
        return await provider.getBalance(signerAddress);
    };

    static getERC20Balances = async (tokenAddresses, network, batchSize=multicall3DefaultBatchSize) => {
        const provider = network ?
            ethers.getDefaultProvider(network) :
            await this.getProvider();
        const signer = (await this.getProvider()).getSigner();
        const signerAddress = await signer.getAddress();

        const erc20Interface = new Interface(ERC20ABI);
        const callData = erc20Interface.encodeFunctionData('balanceOf', [signerAddress]);

        const multicall3Instance = new ethers.Contract(
            this.multicall3Address,
            Multicall3ABI,
            provider,
        );

        const calls = tokenAddresses.map((target) => (target !== constants.AddressZero ? {
            target,
            callData,
            allowFailure: true,
        } : {
            target: this.multicall3Address,
            callData: multicall3Instance.interface.encodeFunctionData('getEthBalance', [signerAddress]),
            allowFailure: true,
        }));

        return (await Promise.all(_.chunk(calls, batchSize).map(
            async (batch) => multicall3Instance.callStatic.aggregate3(batch)
        ))).flatMap((data) => data.map(([success, balance]) => success ? ethers.BigNumber.from(balance) : null));
    };

    static getERC20Balance = async (tokenAddress, network) => {
        const provider = network ?
            ethers.getDefaultProvider(network) :
            await this.getProvider();
        const signer = (await this.getProvider()).getSigner();
        const signerAddress = await signer.getAddress();
        const erc20Instance = new ethers.Contract(
            tokenAddress,
            ERC20ABI,
            provider
        );
        return await erc20Instance.balanceOf(signerAddress);
    };

    static setRouterERC20Approval = async (tokenAddress) => {
        return await this.setERC20Approval(this.vRouterAddress, tokenAddress);
    };

    static setERC20Approval = async (spenderAddress, tokenAddress, approval=ethers.constants.MaxUint256) => {
        const provider = await this.getProvider();
        const signer = provider.getSigner();
        const erc20Instance = new ethers.Contract(
            tokenAddress,
            ERC20ABI,
            provider,
            signer
        ).connect(signer);
        const gasLimit = (await erc20Instance.estimateGas.approve(spenderAddress, approval)).mul(11).div(10);
        let tx = await erc20Instance.approve(spenderAddress, approval, { gasLimit });
        await tx.wait();
        return tx.hash;
    };

    static getStakerAllowances = async (lpTokens, vrsw, staker, network) => {
        const tokenAddresses = [vrsw ?? this.vrswAddress, ...lpTokens];
        const [vrswAllowance, ...lpTokensAllowances] = await this.getERC20Allowances(
          staker ?? this.vrswStakerAddress,
            tokenAddresses,
            network ?? this.cachedRpcUrl,
        );
        return {
            vrswAllowance,
            lpTokensAllowances,
        };
    };

    static getLPtakerAllowance = async (lpToken) => {
        return await this.getERC20Allowance(this.vrswStakerAddress, lpToken, this.cachedRpcUrl);
    };

    static getVRSWStakerAllowance = async () => {
        return await this.getERC20Allowance(
            this.vrswStakerAddress,
            this.vrswAddress,
            this.cachedRpcUrl,
        );
    };

    static getgVRSWStakerAllowance = async () => {
        return await this.getERC20Allowance(
            this.vrswStakerAddress,
            this.gvrswAddress,
            this.cachedRpcUrl,
        );
    };

    static approveLPTokenStaker = async (lpToken) => {
        return await this.setERC20Approval(this.vrswStakerAddress, lpToken);
    };

    static approveVRSWStaker = async () => {
        return await this.setERC20Approval(
            this.vrswStakerAddress,
            this.vrswAddress,
        );
    };

    static approvegVRSWStaker = async () => {
        return await this.setERC20Approval(
            this.vrswStakerAddress,
            this.gvrswAddress,
        );
    };

    static getERC20Allowance = async (spenderAddress, tokenAddress, network) => {
        const provider = network ?
            ethers.getDefaultProvider(network) :
            await this.getProvider();
        const signer = (await this.getProvider()).getSigner();
        const erc20Instance = new ethers.Contract(
            tokenAddress,
            ERC20ABI,
            provider
        );
        const signerAddress = await signer.getAddress();
        return await erc20Instance.allowance(
            signerAddress,
            spenderAddress
        );
    };

    static getERC20Allowances = async (spenderAddress, tokenAddresses, network, batchSize=multicall3DefaultBatchSize) => {
        const provider = network ?
            ethers.getDefaultProvider(network) :
            await this.getProvider();
        const signer = (await this.getProvider()).getSigner();
        const signerAddress = await signer.getAddress();

        const erc20Interface = new Interface(ERC20ABI);
        const callData = erc20Interface.encodeFunctionData('allowance', [signerAddress, spenderAddress]);

        const multicall3Instance = new ethers.Contract(
            this.multicall3Address,
            Multicall3ABI,
            provider,
        );

        const calls = tokenAddresses.map((target) => ({
            target,
            callData,
            allowFailure: true,
        }));

        return (await Promise.all(_.chunk(calls, batchSize).map(
            async (batch) => multicall3Instance.callStatic.aggregate3(batch)
        ))).flatMap((data) => data.map(([success, allowance]) => success ? ethers.BigNumber.from(allowance) : ethers.constants.Zero));
    };

    static showApproveButton = async (tokenAddress, amount, tokenDecimals) => {
        const provider = await this.getProvider();
        const signer = provider.getSigner();
        const erc20Instance = new ethers.Contract(
            tokenAddress,
            ERC20ABI,
            provider
        );
        erc20Instance.connect(signer);
        const signerAddress = await signer.getAddress();
        let allowance = await erc20Instance.allowance(
            signerAddress,
            this.vRouterAddress
        );

        allowance = new Decimal(
            ethers.utils.formatUnits(allowance, tokenDecimals)
        );
        let userAmount = new Decimal(amount).toFixed(18);

        return allowance.lt(userAmount);
    };

    static getRouterERC20Allowance = async (tokenAddress) => {
        return await this.getERC20Allowance(this.vRouterAddress, tokenAddress, this.cachedRpcUrl);
    };

    static getCurrentBlock = async () => {
        const provider = await this.getProvider();
        if (provider) {
            return await provider.getBlockNumber();
        }
    };

    static getFutureTS = async () => {
        const provider = await this.getProvider();
        return (await provider.getBlock('latest')).timestamp + 100000;
    };

    static unstakeLPToken = async (lpToken, amount) => {
        const stakerInstance = await this.getvStaker();
        const gasLimit = (await stakerInstance.estimateGas.unstakeLp(lpToken, amount)).mul(11).div(10);
        let tx = await stakerInstance.unstakeLp(lpToken, amount, { gasLimit });
        await tx.wait();
        return tx.hash;
    };

    static stakeLPToken = async (lpToken, amount) => {
        amount = amount.toString();
        const stakerInstance = await this.getvStaker();
        const gasLimit = (await stakerInstance.estimateGas.stakeLp(lpToken, amount)).mul(11).div(10);
        // eslint-disable-next-line
        const transaction = await stakerInstance.stakeLp(lpToken, amount, { gasLimit });
        await transaction.wait();
        return transaction.hash;
    };

    static async claimRewards(lpToken) {
        const stakerInstance = await this.getvStaker();
        const gasLimit = (await stakerInstance.estimateGas.claimRewards(lpToken)).mul(11).div(10);
        const transaction = await stakerInstance.claimRewards(lpToken, { gasLimit });
        await transaction.wait();
        return transaction.hash;
    }

    static viewLPRewards = async (lpToken) => {
        const stakerInstance = await this.getvStaker();
        const provider = await this.getProvider();
        const signer = provider.getSigner();
        const signerAddress = await signer.getAddress();
        const rewards = await stakerInstance.viewRewards(
            signerAddress,
            lpToken,
            this.vrswAddress
        );
        let ret = parseFloat(ethers.utils.formatEther(rewards)).toFixed(3);
        return ret;
    };

    static viewPartnerRewards = async (lpToken, partnerToken) => {
        const stakerInstance = await this.getvStaker();
        const provider = await this.getProvider();
        const signer = provider.getSigner();

        const signerAddress = await signer.getAddress();

        let rewards = await stakerInstance.callStatic.viewRewards(
            signerAddress,
            lpToken,
            partnerToken
        );

        let ret = parseFloat(ethers.utils.formatEther(rewards)).toFixed(3);

        return ret;
    };

    static viewLpStakes = async () => {
        const stakerInstance = await this.getvStaker();
        let lpStakes = await stakerInstance.viewLpStakes();
        return lpStakes;
    };

    static viewVRSWRewards = async () => {
        const stakerInstance = await this.getvStaker();
        const provider = await this.getProvider();
        const signer = provider.getSigner();
        const signerAddress = await signer.getAddress();
        try {
            let rewards = await stakerInstance.viewRewards(
                signerAddress,
                constants.AddressZero,
                this.vrswAddress
            );
            let ret = parseFloat(ethers.utils.formatEther(rewards)).toFixed(3);
            return ret;
        } catch (err) {
            console.log('Error------------>', err);
        }
    };

    static claimVRSWRewards = async () => {
        const stakerInstance = await this.getvStaker();
        const gasLimit = (await stakerInstance.estimateGas.claimRewards(constants.AddressZero)).mul(11).div(10);
        const transaction = await stakerInstance.claimRewards(
            constants.AddressZero,
            { gasLimit }
        );
        await transaction.wait();
        return transaction.hash;
    };

    static stakeVRSW = async (amount) => {
        const stakerInstance = await this.getvStaker();

        const gasLimit = (await stakerInstance.estimateGas.stakeVrsw(
            amount
        )).mul(11).div(10);

        let tx = await stakerInstance.stakeVrsw(amount, { gasLimit });
        await tx.wait();
        return tx.hash;
    };

    static lockVRSW = async (amount, duration) => {
        const stakerInstance = await this.getvStaker();

        const lockSecs = duration * SECS_IN_WEEK;

        const gasLimit = (await stakerInstance.estimateGas.lockVrsw(
            amount,
            lockSecs
        )).mul(15).div(10);

        let tx = await stakerInstance.lockVrsw(amount, lockSecs, { gasLimit });
        await tx.wait();
        return tx.hash;
    };

    static unlockVRSW = async (amount) => {
        const provider = await this.getProvider();
        const signer = provider.getSigner();
        const stakerInstance = await this.getvStaker();
        const signerAddress = await signer.getAddress();

        const unlockedPositions = await stakerInstance.callStatic.checkLock(
            signerAddress
        );
        for (let pos of unlockedPositions) {
            const stake = await stakerInstance.callStatic.vrswStakes(
                signerAddress,
                pos
            );
            if (stake.amount.toString() == amount.toString()) {
                const gasLimit =
                    (await stakerInstance.estimateGas.unlockVrsw(
                        signerAddress,
                        pos
                    )).mul(150).div(100);

                let tx = await stakerInstance.unlockVrsw(signerAddress, pos, {
                    gasLimit,
                });
                await tx.wait();
                return tx.hash;
            }
        }
    };

    static unstakeVRSW = async (amount) => {
        const stakerInstance = await this.getvStaker();
        const gasLimit = (await stakerInstance.estimateGas.unstakeVrsw(amount)).mul(11).div(10);
        let tx = await stakerInstance.unstakeVrsw(amount, { gasLimit });
        await tx.wait();
        return tx.hash;
    };

    static unstakeLP = async (lpToken) => {
        const stakerInstance = await this.getvStaker();
        const lpStakes = await stakerInstance.viewLpStakes();
        const lpStake = lpStakes.find((lp) => lp.lpToken === lpToken);
        const gasLimit = (await stakerInstance.estimateGas.unstakeLp(
            lpToken,
            lpStake.amount.toString()
        )).mul(11).div(10);
        let tx = await stakerInstance.unstakeLp(
            lpToken,
            lpStake.amount.toString(),
            { gasLimit }
        );
        await tx.wait();
        return tx.hash;
    };

    static getAmountsOut = async (path, amountIn) => {
        if (amountIn > 0) {
            const vRouter = await this.getvRouter();
            let amountsOut;
            try {
                amountsOut = await vRouter.getAmountsOut(path, amountIn);
            } catch (ex) {
                console.log(ex);
            }

            return amountsOut;
        }

        return 0;
    };

    static getAmountOut = async (tokenIn, tokenOut, amountIn) => {
        if (amountIn > 0) {
            const vRouter = await this.getvRouter();

            const amountOut = await vRouter.getAmountOut(
                tokenIn,
                tokenOut,
                amountIn.toString()
            );

            return amountOut;
        }
        return '0.00';
    };

    static getVirtualPool = async (jkAddress, ikAddress) => {
        const vRouter = await this.getvRouter();

        const _vPool = await vRouter.getVirtualPool(jkAddress, ikAddress);

        return {
            fee: _vPool[0],
            token0: _vPool[1],
            token1: _vPool[2],
            balance0: _vPool[3],
            balance1: _vPool[4],
            commonToken: _vPool[5],
        };
    };

    static getAmountIn = async (tokenIn, tokenOut, amountOut) => {
        if (amountOut > 0) {
            const vRouter = await this.getvRouter();

            const amountIn = await vRouter.getAmountIn(
                tokenIn,
                tokenOut,
                amountOut.toString()
            );

            return amountIn;
        }
        return '0.00';
    };

    static getVirtualAmountIn = async (jkPool, ikPool, amountOut) => {
        if (amountOut > 0) {
            const vRouter = await this.getvRouter();

            const amountIn = await vRouter.getVirtualAmountIn(
                jkPool, //jk
                ikPool, //ik
                amountOut.toString()
            );

            return amountIn;
        }
        return '0.00';
    };

    static getVirtualAmountOut = async (jkPool, ikPool, amountIn) => {
        const vRouter = await this.getvRouter();

        const amountOut = await vRouter.getVirtualAmountOut(
            jkPool, //jk
            ikPool, //ik
            amountIn
        );

        return amountOut;
    };

    static formatEther = (wei) => {
        return wei && ethers.utils.formatEther(wei.toString());
    };

    static parseEther = (eth) => {
        return eth && ethers.utils.parseEther(eth.toString());
    };

    static quote = async (tokenIn, tokenOut, amountIn, guid) => {
        const vRouter = await this.getvRouter();

        const amountOut = await vRouter.quote(
            tokenIn,
            tokenOut,
            amountIn.toString()
        );
        const result = {
            amountIn: amountIn,
            amountOut: amountOut,
            guid: guid,
        };

        return result;
    };

    static useSigner = async (signerCallback) => {
        const provider = await this.getProvider();
        const signer = await provider.getSigner();
        return await signerCallback(signer);
    };

    static async addLiquidity(
        token0Address,
        token1Address,
        amount0Desired,
        amount1Desired,
        minAmount0,
        minAmount1
    ) {
        const vRouter = await this.getvRouter();
        const provider = await this.getProvider();
        const signer = provider.getSigner();
        const address = await signer.getAddress();
        const futureTs = await this.getFutureTS();
        const gasLimit = (await vRouter.connect(signer).estimateGas.addLiquidity(
            token0Address,
            token1Address,
            amount0Desired?.toString(),
            amount1Desired?.toString(),
            minAmount0?.toString(),
            minAmount1?.toString(),
            address,
            futureTs
        )).mul(11).div(10);
        let tx = await vRouter.connect(signer).addLiquidity(
            token0Address,
            token1Address,
            amount0Desired?.toString(),
            amount1Desired?.toString(),
            minAmount0?.toString(),
            minAmount1?.toString(),
            address,
            futureTs,
            { gasLimit }
        );
        await tx.wait();
        return tx.hash;
    }

    static async removeLiquidity(
        token0Address,
        token1Address,
        amount0Desired,
        amount1Desired,
        amountToWithdraw
    ) {
        const vRouter = await this.getvRouter();
        const provider = await this.getProvider();
        const signer = provider.getSigner();
        const address = await signer.getAddress();
        const futureTs = await this.getFutureTS();

        const gasLimit = (await vRouter.connect(signer).estimateGas.removeLiquidity(
            token0Address,
            token1Address,
            amountToWithdraw.toString(),
            amount0Desired.toString(),
            amount1Desired.toString(),
            address,
            futureTs
        )).mul(11).div(10);
        const tx = await vRouter
            .connect(signer)
            .removeLiquidity(
                token0Address,
                token1Address,
                amountToWithdraw.toString(),
                amount0Desired.toString(),
                amount1Desired.toString(),
                address,
                futureTs,
                { gasLimit }
            );
        await tx.wait();

        return tx.hash;
    }

    static async addBloxRouteToMetamask() {
        const txHash = await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
                {
                    chainId: '0x89',
                    chainName: 'Polygon POS MEV Protected',
                    nativeCurrency: {
                        name: 'MATIC',
                        symbol: 'MATIC',
                        decimals: 18,
                    },
                    rpcUrls: [process.env.REACT_APP_BLOXROUTE_POLYGON_RPC_URL],
                    blockExplorerUrls: [
                        process.env.REACT_APP_BLOXROUTE_POLYGON_SCAN,
                    ],
                },
            ],
        });
        return txHash;
    }

    static async addTokenToMetamask(tokenAddress, tokenSymbol, decimals, logo) {
        // try {
        // wasAdded is a boolean. Like any RPC method, an error may be thrown.
        const wasAdded = await window.ethereum.request({
            method: 'wallet_watchAsset',
            params: {
                type: 'ERC20', // Initially only supports ERC20, but eventually more!
                options: {
                    address: tokenAddress, // The address that the token is at.
                    symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
                    decimals: decimals, // The number of decimals in the token
                    image: logo, // A string url of the token logo
                },
            },
        });
        return wasAdded;
        // } catch (error) {
        //   return error
        // }
    }
}
