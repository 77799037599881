/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable no-extend-native */
import { ethers, constants } from 'ethers';
import { Decimal } from 'decimal.js-light';
import _, { isObject } from 'lodash';
import { Router, Token as RouterToken } from '@virtuswap/v1-sdk';
import store from '../store/index';
import { BigNumber } from 'ethers';
import { RawPool } from 'router/entities/RawPool';
import { Token } from 'router/entities/Token';
import { plainToClass } from 'class-transformer';
import { handleDecimal } from 'components/common/CommonFunctions';
import { defaultChainId } from 'components/DummyData';

Decimal.set({ precision: 30 });

const web3Service = require('./web3Service').default;

function getSubperiodLength(period, from = 0, to = 1) {
    switch (period) {
        case '1D':
            return 1 * 60 * 60 * 1000;
        case '1W':
            return 4 * 60 * 60 * 1000;
        case '1M':
            return 24 * 60 * 60 * 1000;
        case '3M':
            return 2 * 24 * 60 * 60 * 1000;
        case '1Y':
            return 8 * 24 * 60 * 60 * 1000;
        default:
            return Math.max((to - from) / 45, 24 * 60 * 60 * 1000);
    }
}

function getSubperiodsNumber(period) {
    switch (period) {
        case '1D':
            return 24;
        case '1W':
            return 42;
        case '1M':
            return 30;
        case '3M':
            return 45;
        case '1Y':
            return 45;
        default:
            return 45;
    }
}

Number.prototype.toFixedNumber = function (digits, base) {
    var pow = Math.pow(base || 10, digits);
    return Math.round(this * pow) / pow;
};

const poolsCache = new WeakMap();
const tokensCache = new WeakMap();
export default {
    getRawPoolsFromStore() {
        const rawPools = store.getState().Trade.RawPools;
        if (!poolsCache.has(rawPools)) {
            const pools = rawPools.map((p) => plainToClass(RawPool, p));
            poolsCache.set(rawPools, pools);
        }
        return poolsCache.get(rawPools);
    },
    getTokensFromStore() {
        const rawTokens = store.getState().Trade.selectedChain?.part === 1
            ? store.getState().Trade.ProfileToken1
            : store.getState().Trade.ProfileToken2;
        if (!tokensCache.has(rawTokens)) {
            const tokens = rawTokens.map((t) => plainToClass(Token, t));
            tokensCache.set(rawTokens, tokens);
        }
        return tokensCache.get(rawTokens);
    },

    getPoolFromStore(address) {
        address = address?.toString()?.toLowerCase();
        let pools = this.getRawPoolsFromStore();
        return pools.find((p) => p.poolAddress === address);
    },
    getPoolFromStoreByTokens(token0, token1) {
        token0 = token0?.toString()?.toLowerCase();
        token1 = token1?.toString()?.toLowerCase();
        let pools = this.getRawPoolsFromStore();
        return pools.find(
            (p) =>
                (p.token0 === token0 && p.token1 === token1) ||
                (p.token0 === token1 && p.token1 === token0)
        );
    },
    formatUnits(amount, decimals) {
        if (typeof amount === 'string' || typeof amount === 'number') {
            amount = ethers.utils.parseUnits(String(amount), decimals);
        }
        if (!ethers.BigNumber.isBigNumber(amount)) {
            return 0;
        }
        if (!isObject(amount)) amount = BigNumber.from(String(amount));
        if (isNaN(amount)) return 0;
        if (decimals === undefined) decimals = 18;
        return ethers.utils.formatUnits(amount, decimals);
    },
    parseUnits(amount, decimals) {
        return ethers.utils.parseUnits(amount, decimals);
    },

    getRpcUrl(chainId) {
        const { currentChainId, ChainList } = store.getState().Trade;
        chainId ??= currentChainId ?? defaultChainId;

        const chainInfo = ChainList.find(chain => chain.id === chainId);
        return chainInfo?.rpc_url;
    },

    getNativeCurrency(chainId) {
        const { currentChainId, ChainList } = store.getState().Trade;
        chainId ??= currentChainId ?? defaultChainId;

        const chainInfo = ChainList.find(chain => chain.id === chainId);
        return chainInfo?.native_currency ?? 'ETH';
    },

    getTokenInfo(token) {
        token = token?.toString();
        let tokens = store
            .getState()
            .Trade.ProfileToken1.concat(store.getState().Trade.ProfileToken2);
        return tokens.find((t) => t.address === token) ?? null;
    },

    getTokenDecimal(token) {
        token = token?.toString();
        let tokens = store
            .getState()
            .Trade.ProfileToken1.concat(store.getState().Trade.ProfileToken2);
        let tokenInfo = tokens.find((t) => t.address === token);
        if (!tokenInfo) return 18;
        else return tokenInfo.decimals;
    },

    async setRouterERC20Approval(tokenAddress) {
        tokenAddress = tokenAddress?.toString();
        return await web3Service.setRouterERC20Approval(tokenAddress);
    },

    async getStakerAllowances(lpTokens, vrsw, staker, network) {
        const {
            vrswAllowance,
            lpTokensAllowances,
        } = await web3Service.getStakerAllowances(lpTokens, vrsw, staker, network);

        return Object.fromEntries([
            [
                vrsw ?? web3Service.vrswAddress,
                ethers.utils.formatUnits(vrswAllowance, 18),
            ],
            ...lpTokensAllowances.map((allowance, i) => ([
                lpTokens[i],
                ethers.utils.formatUnits(allowance, 18),
            ]))
        ]);
    },

    async getLPStakerAllowance(lpToken) {
        let allowance = await web3Service.getLPtakerAllowance(lpToken);
        return ethers.utils.formatUnits(allowance, 18);
    },

    async getVRSWStakerAllowance() {
        let vrswAllowance = await web3Service.getVRSWStakerAllowance();
        let adjusted = await ethers.utils.formatUnits(vrswAllowance, 18);
        return adjusted;
    },
    async getgVRSWStakerAllowance() {
        let gvrswAllowance = web3Service.getgVRSWStakerAllowance();
        let adjusted = ethers.utils.formatUnits(gvrswAllowance, 18);
        return adjusted;
    },

    async showApproveButton(tokenAddress, amount) {
        tokenAddress = tokenAddress?.toString();
        let tokenDecimals = this.getTokenDecimal(tokenAddress);

        let showApprove = await web3Service.showApproveButton(
            tokenAddress,
            amount,
            tokenDecimals
        );

        return showApprove;
    },

    async quote(tokenIn, tokenOut, amountIn, guid) {
        tokenIn = tokenIn?.toString();
        tokenOut = tokenOut?.toString();
        let tempamountIn = ethers.utils
            .parseUnits(
                new Decimal(amountIn).toFixed(this.getTokenDecimal(tokenIn)),
                this.getTokenDecimal(tokenIn)
            )
            .toString();

        let res = await web3Service.quote(
            tokenIn,
            tokenOut,
            tempamountIn,
            guid
        );
        res.amountOut = parseFloat(
            ethers.utils.formatUnits(
                res.amountOut,
                this.getTokenDecimal(tokenOut)
            )
        );

        res.amountIn = handleDecimal(amountIn);
        return res;
    },

    async getERC20Allowance(spender, tokenAddress) {
        tokenAddress = tokenAddress?.toString();
        let allowance = await web3Service.getERC20Allowance(
            spender,
            tokenAddress
        );
        let tokenDecimals = this.getTokenDecimal(tokenAddress);
        return ethers.utils.formatUnits(allowance, tokenDecimals);
    },
    async removeLiquidity(
        token0Address,
        token1Address,
        amountToWithdraw,
        amount0Desired,
        amount1Desired
    ) {
        let decimals0 = this.getTokenDecimal(token0Address);
        let decimals1 = this.getTokenDecimal(token1Address);

        let amount0 = ethers.utils.parseUnits(
            new Decimal(amount0Desired).toFixed(decimals0),
            decimals0
        );

        let amount1 = ethers.utils.parseUnits(
            new Decimal(amount1Desired).toFixed(decimals1),
            decimals1
        );

        return await web3Service.removeLiquidity(
            token0Address,
            token1Address,
            amount0.mul(98).div(100),
            amount1.mul(98).div(100),
            amountToWithdraw
        );
    },

    async addLiquidity(token0Address, token1Address, amount0, amount1) {
        let decimals0 = this.getTokenDecimal(token0Address);
        let decimals1 = this.getTokenDecimal(token1Address);

        amount0 = ethers.utils.parseUnits(
            new Decimal(amount0).toFixed(decimals0),
            decimals0
        );

        amount1 = ethers.utils.parseUnits(
            new Decimal(amount1).toFixed(decimals1),
            decimals1
        );

        return await web3Service.addLiquidity(
            token0Address,
            token1Address,
            amount0,
            amount1,
            amount0.mul(98).div(100),
            amount1.mul(98).div(100)
        );
    },

    async lockVRSW(amount, duration) {
        const formattedAmount = ethers.utils.parseUnits(
            new Decimal(amount).toFixed(18)
        );
        return await web3Service.lockVRSW(formattedAmount, duration);
    },

    async unlockVRSW(amount) {
        const formattedAmount = ethers.utils.parseUnits(
            new Decimal(amount).toFixed(18)
        );
        return await web3Service.unlockVRSW(formattedAmount);
    },

    async stakeVRSW(amount) {
        const formattedAmount = ethers.utils.parseUnits(
            new Decimal(amount).toFixed(18)
        );
        return await web3Service.stakeVRSW(formattedAmount);
    },

    async claimVRSWRewards() {
        return await web3Service.claimVRSWRewards();
    },

    async claimRewards(lpToken) {
        return await web3Service.claimRewards(lpToken);
    },

    async viewVRSWRewards() {
        return await web3Service.viewVRSWRewards();
    },

    async viewLPRewards(lpToken) {
        return await web3Service.viewLPRewards(lpToken);
    },

    async viewLPPartnerRewards(lpToken, partnerToken) {
        return await web3Service.viewPartnerRewards(lpToken, partnerToken);
    },

    async stakeLPToken(lpToken, amount) {
        const formattedAmount = ethers.utils.parseUnits(
            new Decimal(amount).toFixed(18)
        );
        return await web3Service.stakeLPToken(lpToken, formattedAmount);
    },

    async unstakeLPToken(lpToken, amount) {
        const formattedAmount = ethers.utils.parseUnits(
            new Decimal(amount).toFixed(18)
        );
        return await web3Service.unstakeLPToken(lpToken, formattedAmount);
    },

    async viewVRSWStake() {
        let stakes = await web3Service.viewLpStakes();
        let vrswStake = stakes
            // eslint-disable-next-line array-callback-return
            .map((stake) => {
                if (stake.lpToken === constants.AddressZero) {
                    let aStake = {};
                    aStake.lpToken = stake.lpToken;
                    aStake.bn = stake.amount.toString();
                    aStake.amount = ethers.utils.formatUnits(stake.amount, 18);
                    return aStake;
                }
            })
            .filter((stake) => stake !== undefined);

        return vrswStake[0];
    },

    async viewLPStakes() {
        let stakes = await web3Service.viewLpStakes();
        stakes = stakes
            // eslint-disable-next-line array-callback-return
            .map((stake) => {
                if (stake.lpToken !== constants.AddressZero) {
                    let aStake = {};
                    aStake.lpToken = stake.lpToken;
                    aStake.bn = stake.amount.toString();
                    aStake.amount = ethers.utils.formatUnits(stake.amount, 18);
                    return aStake;
                }
            })
            .filter((stake) => stake !== undefined);

        return stakes;
    },

    async getBalance(network) {
        const balance = await web3Service.getBalance(network);
        return ethers.utils.formatUnits(balance, 18);
    },

    async getERC20Balance(tokenAddress, network) {
        tokenAddress = tokenAddress?.toString();
        let balance = await web3Service.getERC20Balance(tokenAddress, network);
        let tokenDecimals = await this.getTokenDecimal(tokenAddress);
        return ethers.utils.formatUnits(balance, tokenDecimals);
    },

    async getProfileTokensERC20Balances(ProfileToken) {
        if (!ProfileToken) return {};
        const chainId = ProfileToken[0].chainId;

        const network = this.getRpcUrl(chainId) ?? chainId;

        const [addresses, decimals] = _.unzip(
            ProfileToken.map(token => [token.address, token.decimals]),
        );

        const balances = await web3Service.getERC20Balances(addresses, network);

        return {
            [chainId]: Object.fromEntries(balances.map((balance, i) =>
                [addresses[i], ethers.utils.formatUnits(balance, decimals[i])]
            )),
        };
    },

    async getAllERC20Balances() {
        const { ProfileToken1, ProfileToken2 } = store.getState().Trade;

        if (ProfileToken1?.[0]?.chainId && ProfileToken1[0].chainId === ProfileToken2?.[0]?.chainId) {
            return this.getProfileTokensERC20Balances(ProfileToken1);
        }

        const [balances1, balances2] = await Promise.all([
            this.getProfileTokensERC20Balances(ProfileToken1),
            this.getProfileTokensERC20Balances(ProfileToken2),
        ]);

        return {
            ...balances1,
            ...balances2,
        };
    },

    async addTokenToMetamask(tokenAddress, tokenSymbol, decimals, logo) {
        tokenAddress = tokenAddress?.toString();
        return await web3Service.addTokenToMetamask(
            tokenAddress,
            tokenSymbol,
            decimals,
            logo
        );
    },

    async addBloxRouteRPCToMetamask() {
        return await web3Service.addBloxRouteToMetamask();
    },

    async unstakeVRSW(amount) {
        const formattedAmount = ethers.utils.parseUnits(
            new Decimal(amount).toFixed(18)
        );
        return await web3Service.unstakeVRSW(formattedAmount);
    },

    async approveLPTokenStaker(lpToken) {
        return await web3Service.approveLPTokenStaker(lpToken);
    },

    async approveVRSWStaking() {
        return await web3Service.approveVRSWStaker();
    },

    async approvegVRSWStaker() {
        return await web3Service.approvegVRSWStaker();
    },

    async getRouterERC20Allowance(tokenAddress, convertToNumber=true) {
        tokenAddress = tokenAddress?.toString();
        const allowance = await web3Service.getRouterERC20Allowance(tokenAddress);
        const tokenDecimals = this.getTokenDecimal(tokenAddress);
        const formattedAllowance = ethers.utils.formatUnits(allowance, tokenDecimals);
        return convertToNumber ? parseFloat(formattedAllowance) : formattedAllowance;
    },

    async getPoolCurrentReserveRatio(poolAddress) {
        return await web3Service.getPoolCurrentReserveRatio(poolAddress);
    },

    async getMaxVirtualTradeAmountRtoN(ik, jk) {
        return await web3Service.getMaxVirtualTradeAmountRtoN(ik, jk);
    },

    async scanLPTokens(network) {
        const pools = this.getRawPoolsFromStore().map(({ poolAddress }) => poolAddress);
        return (await web3Service.getERC20Balances(pools, network)).map((balance, i) =>({
            poolAddress: pools[i],
            balance: ethers.utils.formatEther(balance),
        }));
    },

    async getPoolLPTokensBalance(poolAddress) {
        let totalSupply = await web3Service.getTokenTotalSupply(poolAddress);
        totalSupply = ethers.utils.formatUnits(totalSupply, 18);
        return totalSupply;
    },

    async prepareSwapTransactions(
        tokenIn,
        tokenOut,
        amount,
        isExactInput,
        chainId,
        guid
    ) {
        if (!tokenIn || !tokenOut) return null;
        try {
            const slippageTolerance = parseFloat(
                store.getState().Trade.txSetting.slippage
            );

            const slippage = 100 / slippageTolerance;

            //amount to BN
            const amountBN = ethers.utils.parseUnits(
                new Decimal(amount)?.toFixed(isExactInput ? tokenIn.decimals : tokenOut.decimals),
                isExactInput ? tokenIn.decimals : tokenOut.decimals
            );

            if (amountBN.eq(0)) return null;

            chainId ??= await web3Service.getCurrentChainId();
            const router = new Router({ slippage, isExactInput, timeoutMs: 750, calculateMetrics: true });
            const rTokenIn = new RouterToken(
                chainId,
                tokenIn.address.toString(),
                tokenIn.decimals
            );
            if (tokenIn?.native) rTokenIn.native = true;
            const rTokenOut = new RouterToken(
                chainId,
                tokenOut.address.toString(),
                tokenOut.decimals,
            );
            if (tokenOut?.native) rTokenOut.native = true;
            const route = await router.getRoute(
                rTokenIn,
                rTokenOut,
                amountBN,
                chainId,
                { slippage, isExactInput },
            );

            const totalIn = route.tokenIn.balance;
            const totalOut = route.tokenOut.balance;
            const slippageThreshold = route.slippageThresholdAmount.balance;

            console.log('totalIn', totalIn.toString());
            console.log('totalOut', totalOut.toString());
            console.log('slippageThreshold', slippageThreshold.toString());

            return {
                route: route,
                totalIn: isExactInput ? totalIn : slippageThreshold,
                totalOut: totalOut,
                expectedAmountIn: totalIn,
                minAmountOut: isExactInput ? slippageThreshold : totalOut,
                realPoolPCT: route.metrics.realPoolPCT,
                vPoolPCT: route.metrics.vPoolPCT,
                triangularPCT: route.metrics.triangularPCT,
                price0: route.metrics.price0.toFixed(6),
                price1: route.metrics.price1.toFixed(6),
                totalSavings: route.metrics.totalSavings.toFixed(2),
                priceImpact: route.metrics.priceImpact.toFixed(3),
                guid: guid,
                avgFee: route.metrics.avgFee,
            };
        } catch (ex) {
            console.error(ex);
            return {
                route: null,
                guid,
            };
        }
    },

    async executeRoute(route) {
      const router = new Router();
      return await web3Service.useSigner(
          async (signer) =>
              router.executeMultiSwapV3(
                  route.chain,
                  router.generateMultiSwapDataV3(route, await signer.getAddress()),
                  signer,
                  route.tokenIn.isNative ? route.isExactInput ? route.tokenIn.balanceBN : route.slippageThresholdAmount.balanceBN : undefined,
              ),
      );
    },

    // Tokens By Reserve Pool
    async getTokensByReservePool(poolReserves, profileToken, liquidity_pool) {
        if (liquidity_pool) {
            const result = [];
            poolReserves?.forEach((arr) => {
                const tempResult = [];
                arr.forEach((address) => {
                    const found = profileToken?.find(
                        (temp) => temp?.address === address
                    );
                    if (found) {
                        tempResult.push(found);
                    }
                });
                result.push(tempResult);
            });
            return result;
        } else {
            let tokens = poolReserves?.map((i) =>
                profileToken?.find(
                    (temp) => i?.reserveAddress === temp?.address
                )
            );
            return tokens;
        }
    },
    //handle Get Balance
    async handleGetBalance(isSection, isToken) {
        let FirstBalance;
        let SecondBalance;
        let result = { FirstBalance: '', SecondBalance: '' };
        //Handle balance query
        if (isSection?.isSwapTokenFirst?.address && !isToken) {
            await this.getERC20Balance(
                isSection?.isSwapTokenFirst?.address
            ).then((status) => {
                FirstBalance = status;
            });
        }
        if (isSection?.isSwapTokenSecond?.address && !isToken) {
            await this.getERC20Balance(
                isSection?.isSwapTokenSecond?.address
            ).then((status) => {
                SecondBalance = status;
            });
        }
        result = { FirstBalance: FirstBalance, SecondBalance: SecondBalance };
        return result;
    },
    async cumulativeDataForPeriod(data, period) {
        const n = getSubperiodsNumber(period);
        let to = new Date(Date.now());
        to.setMilliseconds(0);
        to.setSeconds(0);
        to.setMinutes(0);
        if (period !== '1D' && period !== '1W') to.setHours(0);
        const from = (data.length ? new Date(data[0].timestamp) : to).valueOf();
        const subperiodLength = getSubperiodLength(period, from, to);

        let result = [];
        for (let i = n - 1; i >= 0; --i) {
            result.push({
                timestamp: new Date(to - subperiodLength * i),
                value: 0,
            });
        }

        const resultReversed = result.reverse();
        for (let i = 0; i < data.length; ++i) {
            const timestamp = new Date(data[i].timestamp);
            const obj = resultReversed.find(
                (v) => timestamp >= v.timestamp
            );
            if (obj) obj.value += parseFloat(data[i].value);
        }
        resultReversed.reverse();
        return result;
    },
};
