export const SAVE_WALLET_ADDRESS = '/saveWalletAddress';
//get Tokens
export const FETCH_TOKEN = '/tokens';
//get Pools
export const FETCH_POOLS_LIST = '/pools';
//get poolReserves
export const FETCH_POOL_RESERVES = '/poolReserves';
//get many poolsReserves
export const FETCH_MANY_POOLS_RESERVES = '/poolsReserves';
//get Withdraw Pool
export const FETCH_WITHDRAW_POOLS_LIST = '/pool';
//Fetch LockDrop Featured Pools
export const LD_FEATURED_POOLS = '/lockdropPools';
//Fetch Pools Info
export const FETCH_INFO_POOLS = '/poolInfo';
//Fetch Pools List Featured
export const FETCH_PLS_FEATURED = '/poolsInfo';
//Fetch Initium AirDrop Eligible
export const FETCH_ELIGIBILITY = '/airdropEligible';
//Fetch AirDrop Transactions
export const FETCH_AD_TRANSACTION = '/airdropTxs';
//Fetch VRSW Positions
export const FETCH_VRSW_PS = '/positions';
//Get Initium AirDrop Wallet proof
export const GET_AD_PS = '/airdropWalletProof';
//Fetch Community AirDrop Eligible
export const FETCH_COM_ELIGIBILITY = '/comAirdropEligible';
//Get Community AirDrop Wallet proof
export const GET_COM_AD_PS = '/comAirdropWalletProof';
//Get Community Activities
export const GET_COM_AD_ACT = '/getActivitiesCommunity';
//Boost Calculator
export const GET_BOOST_CAL = '/boostCalculator';
//LP Balances
export const GET_LP_BALANCES = '/lpBalances';
//get Chain List
export const FETCH_CHAIN = '/chains';
