import store from 'store/index';
import { Decimal } from 'decimal.js-light';
import vSwapHelpers from 'util/vSwapHelpers';
import { getFriendlyErrorMessage } from 'util/errorMapping';
import { plainToClass } from 'class-transformer';
import { Token } from '../../router/entities/Token';
import { fillColors, graphOneSeries } from 'components/DummyData';
import {
    getRecentTransactions,
    getSelectedChain,
    setCalculatedTransactions,
    setIsTradeLoad,
    setTransactionLoader,
} from 'store/action';

//handle Select Assets Token
export const handleSelectAssetsToken = ({
    dispatch,
    element,
    selectedChain,
    isSelectId,
    isSection,
    setIsSection,
    setIsSelectionChanged,
    setIsGetApprove,
    isGetApprove,
    setIsAllowance,
    setIsShowApprove,
    wrappedNativeSwapToken,
}) => {
    const tokenFirstPriceTag = (isSection?.isSwapTokenFirst?.isNative
        ? wrappedNativeSwapToken?.PriceTag : undefined)
        ?? isSection?.isSwapTokenFirst?.PriceTag;
    const tokenSecondPriceTag = (isSection?.isSwapTokenSecond?.isNative
        ? wrappedNativeSwapToken?.PriceTag : undefined)
        ?? isSection?.isSwapTokenSecond?.PriceTag;
    const elementPriceTag = (element?.isNative
        ? wrappedNativeSwapToken?.PriceTag : undefined)
        ?? element?.PriceTag;

    if (isSelectId === 2 && tokenFirstPriceTag !== elementPriceTag) {
        setIsSection((prev) => ({
            ...prev,
            isSwapTokenSecond: element,
        }));
    } else {
        if (isSelectId === 1 && tokenSecondPriceTag !== elementPriceTag) {
            setIsSection((prev) => ({
                ...prev,
                isSwapTokenFirst: element,
            }));
        } else {
            if (isSelectId === 1) {
                if (isSection?.isGetChain1?.id !== isSection?.isGetChain2.id) {
                    setIsSection((prev) => ({
                        ...prev,
                        isSwapTokenFirst: element,
                    }));
                } else {
                    setIsSection((prev) => ({
                        ...prev,
                        isSwapTokenFirst: element,
                        isSwapTokenSecond: '',
                        isBalance1: handleDecimal(isSection?.isBalance2),
                        isBalance2: 0,
                        isValueSecond: '',
                        isGetChain1: selectedChain?.chainId1,
                        isGetChain2: selectedChain?.chainId2,
                    }));
                    if (isGetApprove) {
                        setIsGetApprove((prev) => ({
                            ...prev,
                            approveToken1: isGetApprove?.approveToken2,
                            approveToken2: isGetApprove?.approveToken1,
                            showApproveToken1: isGetApprove?.showApproveToken2,
                            showApproveToken2: isGetApprove?.showApproveToken1,
                        }));
                        setIsShowApprove({
                            showApproveToken1: isGetApprove?.showApproveToken2,
                            showApproveToken2: isGetApprove?.showApproveToken1,
                        });
                    }
                }
            } else {
                if (isSection?.isGetChain1?.id !== isSection?.isGetChain2.id) {
                    setIsSection((prev) => ({
                        ...prev,
                        isSwapTokenSecond: element,
                    }));
                } else {
                    setIsSection((prev) => ({
                        ...prev,
                        isSwapTokenFirst: '',
                        isSwapTokenSecond: element,
                        isBalance1: 0,
                        isBalance2: isSection?.isBalance1,
                        isValueFirst: '',
                        isGetChain1: isSection?.isGetChain2,
                        isGetChain2: isSection?.isGetChain1,
                    }));
                    dispatch(
                        getSelectedChain({
                            chainId1: isSection?.isGetChain2,
                            chainId2: isSection?.isGetChain1,
                            part: isSelectId,
                        })
                    );
                    vSwapHelpers
                        .getRouterERC20Allowance(
                            isSection?.isSwapTokenSecond?.address
                        )
                        .then((i) => {
                            setIsAllowance(i);
                        });
                    if (isGetApprove) {
                        setIsGetApprove((prev) => ({
                            ...prev,
                            approveToken1: isGetApprove?.approveToken2,
                            approveToken2: isGetApprove?.approveToken1,
                            showApproveToken1: isGetApprove?.showApproveToken2,
                            showApproveToken2: isGetApprove?.showApproveToken1,
                        }));
                        setIsShowApprove({
                            showApproveToken1: isGetApprove?.showApproveToken2,
                            showApproveToken2: isGetApprove?.showApproveToken1,
                        });
                    }
                }
            }
            setIsSelectionChanged(true);
        }
    }
};

//get Tokens Data
function getTokensData(id) {
    return id === 1
        ? store
              .getState()
              .Trade.ProfileToken1.map((token) => plainToClass(Token, token))
        : store
              .getState()
              .Trade.ProfileToken2.map((token) => plainToClass(Token, token));
}

//handle Fetch Automatic Trade Route
export const fetchAutomaticRoute = async ({
    isSection,
    isExactIn,
    setIsGetAmount,
    guid,
    tradePage,
    dispatch,
    SwapToken,
    setIsFetchPrice,
    setIsLoaderToken,
    setLastRoute,
    setIsTradeRouteLoader,
    calculatedTransactions,
    setIsAmountFetchedOnce,
    isSwitched,
}) => {
    if (tradePage) {
        setIsTradeRouteLoader(true);
    }
    if (tradePage && calculatedTransactions?.length === 0) {
        dispatch(setCalculatedTransactions());
        setTimeout(() => {
            setIsLoaderToken(false);
        }, 400);
    }
    if (true) {
        let tokensList1 = getTokensData(isSwitched ? 2 : 1);
        let tokensList2 = getTokensData(isSwitched ? 1 : 2);
        let token1 = tokensList1?.find(
            (t) =>
                t.address?.toLowerCase() ===
                isSection.isSwapTokenFirst.address?.toLowerCase()
        );
        let token2 = tokensList2?.find(
            (t) =>
                t.address?.toLowerCase() ===
                isSection.isSwapTokenSecond.address?.toLowerCase()
        );
        let transactions = await vSwapHelpers.prepareSwapTransactions(
            token1,
            token2,
            isExactIn ?
                isSection?.isValueFirst?.toString()?.replace(/,/g, '') :
                isSection?.isValueSecond?.toString()?.replace(/,/g, ''),
            isExactIn,
            isSection?.isGetChain1?.id,
            guid
        );
        if (transactions?.route) {
            if (tradePage) {
                setIsFetchPrice(true);
                setLastRoute(transactions.route);
            }
            if (transactions?.guid === sessionStorage.getItem('GUID')) {
                setIsGetAmount(transactions);
                if (tradePage) setIsAmountFetchedOnce(true);
            } else {
                return;
            }
            let routerSummary = ' ';
            let labelsObj = {
                isExactIn,
                estimatedCost: 0,
                priceImpact: !isNaN(transactions.priceImpact)
                    ? Number(transactions.priceImpact).toFixed(2)
                    : 0.0,
                vPoolPCT: transactions.vPoolPCT,
                realPoolPCT: transactions.realPoolPCT,
                triangularPCT: transactions.triangularPCT,
                minimumReceived: transactions.minAmountOut,
                expectedUsage: transactions.expectedAmountIn,
                amountIn: transactions.totalIn,
                amountOut: transactions.totalOut,
                exchangeRate:
                    '1 ' +
                    isSection.isSwapTokenFirst.PriceTag +
                    ' = ' +
                    transactions.price0 +
                    ' ' +
                    isSection.isSwapTokenSecond.PriceTag,
                inputToken: isSection.isSwapTokenFirst.address,
                inputTokenSymbol: isSection.isSwapTokenFirst.PriceTag,
                outputToken: isSection.isSwapTokenSecond.address,
                outputTokenSymbol: isSection.isSwapTokenSecond.PriceTag,
                route: transactions.route,
                totalSavings: transactions.totalSavings,
                formatted_exchange_Rate: Number(transactions.price0),
                routerSummary: routerSummary,
                avgFee: transactions.avgFee.toFixed(2),
            };
            if (tradePage) {
                dispatch(setCalculatedTransactions(labelsObj));
                setIsTradeRouteLoader(false);
                setIsLoaderToken(true);
                if (SwapToken) {
                    dispatch(
                        setIsTradeLoad({
                            id: 0,
                            status: false,
                        })
                    );
                }
            }
        } else {
            if (tradePage && transactions?.guid === sessionStorage.getItem('GUID')) {
                setLastRoute({ steps: [] });
                dispatch(setCalculatedTransactions());
                setIsTradeRouteLoader(false);
                setIsLoaderToken(false);
                setIsGetAmount(null);
                setIsAmountFetchedOnce(true);
            }
        }
    }
};

//Community Airdrop
export const handleAddVRSWToMetaMask = ({
    dispatch,
    RecentTx,
    walletInfo,
    setIsClaimed,
    setIsConFirm,
    setIsRejectTx,
    setIsSetTxMessage,
    tokenAddress,
    vrswStaked,
    tokenName,
}) => {
    if (!vrswStaked) {
        setIsClaimed(false);
    }
    document.getElementById('root').classList.remove('ld_example_modal_root');
    setIsConFirm(true);
    setIsSetTxMessage(`Add ${tokenName}`);
    setIsRejectTx(false);
    dispatch(setTransactionLoader(true));
    vSwapHelpers
        .addTokenToMetamask(tokenAddress, tokenName, 18, '')
        .then(() => {
            setIsConFirm(false);
            dispatch(setTransactionLoader(false));
        })
        .catch((err) => {
            if (err?.code) {
                const errorMessage = getFriendlyErrorMessage(err);
                setIsRejectTx(errorMessage);
                setIsConFirm(true);
            }
        });
};

//Trade Page - setRouterERC20Approval
export const handleRouterERC20Approval = ({
    dispatch,
    tokenAddress,
    setIsApprove,
    setIsConFirm,
    isSection,
    walletInfo,
    RecentTx,
    handleAllowanceToken,
    setIsRejectTx,
    handleShowApproveButton,
}) => {
    vSwapHelpers
        .setRouterERC20Approval(tokenAddress)
        .then((txHash) => {
            setIsApprove(true);
            handleAllowanceToken();
            setIsConFirm(false);
            setIsApprove(false);
            let transaction = {
                text: `Approve ${isSection.isSwapTokenFirst?.PriceTag}`,
                status: 2,
                timestamp: Math.floor(Date.now() / 1000),
                txHash: txHash,
                walletAddress: walletInfo?.metaMaskAddress,
                chainId: Number(walletInfo?.netID
                    ?? walletInfo?.network?.chain?.id) || undefined,
            };
            // Set Recent transactions in localStorage
            localStorage.setItem(
                'RecentTransactions',
                JSON.stringify(transaction)
            );
            dispatch(getRecentTransactions(!RecentTx));
            dispatch(setTransactionLoader(false));
            handleShowApproveButton(
                isSection?.isSwapTokenFirst?.address,
                isSection?.isValueFirst
            );
        })
        .catch((e) => {
            if (e?.code) {
                const errorMessage = getFriendlyErrorMessage(e);
                setIsRejectTx(errorMessage);
                setIsConFirm(true);
                let transaction = {
                    text: `Approve ${isSection.isSwapTokenFirst?.PriceTag}`,
                    status: e?.code === 'ACTION_REJECTED' ? 1 : 3,
                    timestamp: Math.floor(Date.now() / 1000),
                    txHash: '',
                    walletAddress: walletInfo?.metaMaskAddress,
                };
                // Set Recent transactions in localStorage
                localStorage.setItem(
                    'RecentTransactions',
                    JSON.stringify(transaction)
                );
                dispatch(getRecentTransactions(!RecentTx));
            }
            dispatch(setTransactionLoader(false));
        });
};

// Pie Chart Options
export const handlePieChartOptions = ({
    isPieDesc,
    stakeSimple,
    chartOffsetX,
    chartOffsetY,
}) => {
    let PieChartOptions = {
        series: !!isPieDesc?.values?.length ? isPieDesc?.values : [100],
        labels: !!isPieDesc?.labels?.length
            ? isPieDesc?.labels?.map((key) => {
                  return `${key}`;
              })
            : [],
        colors: !!isPieDesc?.values?.length ? fillColors : ['#252D55'],
        animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
                enabled: true,
                delay: 150,
            },
            dynamicAnimation: {
                enabled: true,
                speed: 350,
            },
        },
        legend: {
            show: false,
            showForSingleSeries: false,
            showForNullSeries: true,
            showForZeroSeries: true,
            position: 'left',
            horizontalAlign: 'center',
            floating: false,
            fontSize: '14px',
            fontFamily: 'Plus Jakarta Sans',
            fontWeight: 600,
            inverseOrder: false,
            width: undefined,
            height: undefined,
            tooltipHoverFormatter: undefined,
            customLegendItems: [],
            offsetX: !stakeSimple ? -30 : -20,
            offsetY: !stakeSimple ? -3 : -20,
            labels: {
                colors: 'var(--system-color-600)',
                useSeriesColors: false,
            },
            markers: {
                width: 10,
                height: 10,
                strokeWidth: 0,
                strokeColor: 'var(--system-color-600)',
                fillColors: fillColors,
                radius: 5,
                customHTML: undefined,
                onClick: undefined,
                offsetX: 0,
                offsetY: 0,
            },
            itemMargin: {
                horizontal: 0,
                vertical: 7,
            },
            onItemClick: {
                toggleDataSeries: false,
            },
            onItemHover: {
                highlightDataSeries: true,
            },
            formatter: function (val, opts) {
                let amount = opts.w.globals.series[opts.seriesIndex];
                return `${val}: $${handleThousandSeparator(Number.parseFloat(Number(amount).toFixed(2)))}`;
            },
        },
        chart: {
            dropShadow: {
                enabled: true,
                enabledOnSeries: undefined,
                top: 0,
                left: 0,
                blur: 5,
                color: '#000',
                opacity: 0.35,
            },
            offsetX: chartOffsetX,
            offsetY: chartOffsetY,
            horizontalAlign: 'left',
        },
        dataLabels: {
            enabled: false,
        },
        plotOptions: {
            pie: {
                startAngle: 0,
                endAngle: 360,
                expandOnClick: false,
                offsetX: 0,
                offsetY: 0,
                customScale: 1,
                dataLabels: {
                    offset: 0,
                    minAngleToShowLabel: 10,
                },
                donut: {
                    size: '80%',
                    background: 'transparent',
                    labels: {
                        show: false,
                    },
                },
            },
        },
        stroke: {
            show: false,
        },
        tooltip: {
            enabled: !!isPieDesc?.values?.length,
            custom: function ({ series, seriesIndex, w }) {
                let val = handleThousandSeparator(Number.parseFloat(Number(series[seriesIndex]).toFixed(2)));
                const color = w.config.colors[seriesIndex];
                return `<span style="background-color:${color}; font-size: 12px; padding: 4px 8px; font-weight: 600;">${isPieDesc?.labels[seriesIndex]}: $${val}</span>`;
            },
        },
    };
    return PieChartOptions;
};

//DAO Stake Simple
export const handlesStakingBarChart = ({
    isPieDesc,
    chartOffsetX,
    chartOffsetY,
}) => {
    let PieChartOptions = {
        series: !!isPieDesc?.values?.length ? isPieDesc?.values : [100],
        labels: !!isPieDesc?.labels?.length
            ? isPieDesc?.labels?.map((key) => {
                  return `${key}`;
              })
            : [],
        colors: !!isPieDesc?.values?.length
            ? ['#F15223', '#F1D023', '#50AF95', '#00A3FF', '#C547E5']
            : ['#252D55'],
        animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
                enabled: true,
                delay: 150,
            },
            dynamicAnimation: {
                enabled: true,
                speed: 350,
            },
        },
        legend: {
            show: !!isPieDesc?.values?.length ? true : false,
            showForSingleSeries: false,
            showForNullSeries: true,
            showForZeroSeries: true,
            position: 'left',
            horizontalAlign: 'center',
            floating: false,
            fontSize: '14px',
            fontFamily: 'Plus Jakarta Sans',
            fontWeight: 600,
            inverseOrder: false,
            width: undefined,
            height: undefined,
            tooltipHoverFormatter: undefined,
            customLegendItems: [],
            offsetX: -20,
            offsetY: -20,
            labels: {
                colors: 'var(--system-color-600)',
                useSeriesColors: false,
            },
            markers: {
                width: 10,
                height: 10,
                strokeWidth: 0,
                strokeColor: 'var(--system-color-600)',
                fillColors: [
                    '#F15223',
                    '#F1D023',
                    '#50AF95',
                    '#00A3FF',
                    '#C547E5',
                ],
                radius: 5,
                customHTML: undefined,
                onClick: undefined,
                offsetX: 0,
                offsetY: 0,
            },
            itemMargin: {
                horizontal: 0,
                vertical: 7,
            },
            onItemClick: {
                toggleDataSeries: false,
            },
            onItemHover: {
                highlightDataSeries: true,
            },
            formatter: function (val, opts) {
                let amount = opts.w.globals.series[opts.seriesIndex];
                return `${val}: ${Number.parseFloat(
                    Number(amount).toFixed(2)
                )}%`;
            },
        },
        chart: {
            dropShadow: {
                enabled: true,
                enabledOnSeries: undefined,
                top: 0,
                left: 0,
                blur: 5,
                color: '#000',
                opacity: 0.35,
            },
            offsetX: chartOffsetX,
            offsetY: chartOffsetY,
        },
        dataLabels: {
            enabled: false,
        },
        plotOptions: {
            pie: {
                startAngle: 0,
                endAngle: 360,
                expandOnClick: false,
                offsetX: 0,
                offsetY: 0,
                customScale: 1,
                dataLabels: {
                    offset: 0,
                    minAngleToShowLabel: 10,
                },
                donut: {
                    size: '80%',
                    background: 'transparent',
                    labels: {
                        show: false,
                    },
                },
            },
        },
        stroke: {
            show: false,
        },
        tooltip: {
            enabled: !!isPieDesc?.values?.length ? true : false,
            custom: function ({ series, seriesIndex, w }) {
                let val = series[seriesIndex];
                return `<span style="font-size: 12px; padding: 4px 8px; font-weight: 600;">${isPieDesc?.labels[seriesIndex]}:  ${val}%</span>`;
            },
        },
        responsive: !!isPieDesc?.values?.length
            ? [
                  {
                      breakpoint: 810,
                      options: {
                          legend: {
                              position: 'bottom',
                              offsetX: 0,
                              offsetY: 10,
                              horizontalAlign: 'left',
                              itemMargin: {
                                  horizontal: 0,
                                  vertical: 2,
                              },
                          },
                          chart: {
                              height: 400,
                          },
                      },
                  },
              ]
            : [],
    };
    return PieChartOptions;
};

//Buy VRSW
export const handleReplaceNavigationWithData = ({ navigate, ProfileToken }) => {
    let VRSWToken = ProfileToken?.find((item) => item?.symbol === 'VRSW');
    navigate('/', {
        replace: true,
        state: {
            VRSWToken: {
                ActiveTab: VRSWToken?.id,
                PriceTag: VRSWToken?.symbol,
                icon: VRSWToken?.icon,
                address: VRSWToken?.address,
                decimals: VRSWToken?.decimals,
                name:
                    VRSWToken?.name?.lastIndexOf(' -') > 0
                        ? VRSWToken?.name?.slice(
                              0,
                              VRSWToken?.name?.lastIndexOf(' -')
                          )
                        : VRSWToken?.name,
                lastUSDPrice: VRSWToken?.lastUSDPrice ?? 0,
                tokenId: VRSWToken?.tokenId,
            },
        },
    });
};

//Handle thousand separator
export const handleThousandSeparator = (number) => {
    const parts = number?.toString()?.split('.');
    if (!!parts?.length) {
        parts[0] = parts[0]?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return parts.join('.');
    }
    return '0';
};

export const handleDecimal = (value) => {
    let amount;
    if (/^-?\d+(\.\d+)?$/.test(value?.toString()?.replace(/,/g, ''))) {
        try {
            value = value?.toString()?.replace(/,/g, '');
            amount = new Decimal(value);
            amount = amount.toFixed(value.split('.')[1]?.length ?? 0);
            return amount;
        } catch {
            return value.slice(0, -1);
        }
    } else {
        return value;
    }
};

//Handle find standard Date formate from given timeStamp
export const handleStandardDate = ({ dateString }) => {
    let result = [];
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    !!dateString?.length &&
        dateString?.map((item) => {
            const dateInUTC = new Date(item); // Parse the UTC date
            const localDate = new Date(
                dateInUTC.getTime() + dateInUTC.getTimezoneOffset() * 60000
            ); // Convert to local time
            const formattedDate = localDate.toLocaleDateString(
                'en-US',
                options
            );
            return result.push(formattedDate);
        });
    return result;
};

//Handle transform Data according to Day, Month, Week & Year
export const convertDataToRange = async ({ historyData, period, tagName }) => {
    try {
        // Use await to wait for the result of sumDataForPeriod
        let startDate = Date.now();
        switch (period) {
            case '1D':
                startDate -= 24 * 60 * 60 * 1000;
                break;
            case '1W':
                startDate -= 7 * 24 * 60 * 60 * 1000;
                break;
            case '1M':
                startDate -= 30 * 24 * 60 * 60 * 1000;
                break;
            case '3M':
                startDate -= 90 * 24 * 60 * 60 * 1000;
                break;
            case '1Y':
                startDate -= 365 * 24 * 60 * 60 * 1000;
                break;
            case 'All':
                startDate = 1694596294000 - 24 * 60 * 60 * 1000; // vRouter creation time minus 1 day as min value
                break;
            default:
                startDate = 1694596294000 - 24 * 60 * 60 * 1000; // vRouter creation time minus 1 day as min value
        }
        const filteredData = historyData
            ?.filter(
                (item) =>
                    ((period == '1D' || period == '1W') && item.isHourly) ||
                    ((period == '1M' ||
                        period == '3M' ||
                        period == '1Y' ||
                        period == 'All') &&
                        !item.isHourly)
            )
            .filter((item) => new Date(item.timestamp) >= startDate);

        let updatedData = filteredData.map((item) => {
            return {
                value: item.value,
                timestamp: item.timestamp,
            };
        });
        if (tagName == 'TVL') {
            const lastValue = historyData?.length > 0
                ? historyData[historyData.length - 1].value
                : 0;
            if (!updatedData.length)
                updatedData.push({
                    timestamp: startDate,
                    value: lastValue,
                });
            updatedData.push({
                timestamp: Date.now(),
                value: lastValue,
            });
        } else if (tagName == 'Fees') {
            updatedData.unshift({
                timestamp: startDate,
                value: 0.0,
            });
            updatedData.push({
                timestamp: Date.now(),
                value: 0.0,
            });
            for (let i = 1; i < updatedData.length; ++i) {
                updatedData[i].value =
                    parseFloat(updatedData[i].value) +
                    parseFloat(updatedData[i - 1].value);
            }
        } else {
            updatedData = await vSwapHelpers.cumulativeDataForPeriod(
                updatedData,
                period
            );
        }
        // Filter the data based on the calculated start date
        const filteredSeries = updatedData?.map((item) => {
            const checkDate = new Date(item?.timestamp);
            const isoString = checkDate.toISOString();
            return {
                name: isoString,
                data: item.value,
            };
        });
        const graphList = [
            {
                name: filteredSeries?.map((item) => item?.name),
                data:
                    tagName === 'Volume' &&
                    filteredSeries.every((item) => item.data === 0)
                        ? []
                        : filteredSeries?.map((item) =>
                              item?.data && Number(item?.data)
                                  ? Number(item?.data)
                                  : 0
                          ),
            },
        ];
        return graphList;
    } catch (error) {
        console.log('error', error);
    }
};

//Set Graph One data by selected tab name history
export const generateGraphList = async (name, historyData) => {
    let graphList = [];
    let duration = [];
    let totalAmount = 0;
    if (!!historyData?.length) {
        let updatedData = historyData?.filter((item) => !item.isHourly);
        graphList = graphOneSeries.map((element) => ({
            ...element,
            name: updatedData?.map((item) => item.timestamp),
            data: updatedData?.map((item) => (item.value ? item.value : 0)),
        }));
        duration = handleStandardDate({
            dateString: updatedData?.map((item) => item.timestamp),
        });
        // get totalAmount
        if (name === 'Volume' || name === 'Fees')
            totalAmount = updatedData.reduce(
                (acc, item) => acc + parseFloat(item.value),
                0.0
            );
        else totalAmount = historyData[historyData.length - 1].value;
    }

    return {
        name,
        graphList,
        duration:
            !!duration?.length &&
            `${duration[0]}-${duration[duration.length - 1]}`,
        totalAmount: `$${Number(totalAmount)?.toFixed(2)}`,
        historyData: historyData,
    };
};

const dateOptions = { year: 'numeric', month: 'short', day: 'numeric' };
const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true };
const dateTimeOptions = { ...dateOptions, ...timeOptions };

// Common Graph tooltip function
export const customGraphTooltip = ({
    seriesIndex,
    dataPointIndex,
    w,
    customKey,
}) => {
    let options;
    if (customKey === '1D' || customKey === '1W') {
        options = dateTimeOptions;
    } else {
        options = dateOptions;
    }
    const name = w.config.series[seriesIndex].name[dataPointIndex];
    const value = w.globals.series[seriesIndex][dataPointIndex];
    const date = new Date(name);
    const formattedDate = date.toLocaleDateString('en-US', options);
    const tooltipBackgroundColor = '#f15223';
    const formattedValue = handleThousandSeparator(
        Number.parseFloat(Number(value)?.toFixed(2))
    );
    return `<div style="background-color: ${tooltipBackgroundColor}; padding: 5px; font-weight: 600;"><strong>${formattedDate}</strong>: $${formattedValue}</div>`;
};

// Graph tooltip function for bar chart
export const customGraphBarTooltip = ({
    seriesIndex,
    dataPointIndex,
    w,
    customKey,
}) => {
    const name = w.config.series[seriesIndex].name[dataPointIndex];
    const nextName = w.config.series[seriesIndex].name[dataPointIndex + 1];
    const date = new Date(name);
    const nextDate = nextName && new Date(nextName);

    let options;
    let datePrefix = '';
    let onlyTime = false;
    if (customKey === '1D' || customKey === '1W') {
        datePrefix = date.toLocaleDateString('en-US', dateOptions) + ': ';
        if (nextDate &&
            date.toLocaleDateString('en-US', dateOptions) ===
            nextDate.toLocaleDateString('en-US', dateOptions)) {
            options = timeOptions;
            onlyTime = true;
        } else {
            options = dateTimeOptions;
            datePrefix = '';
        }
    } else {
        options = dateOptions;
    }

    const formattedDate = date[onlyTime ?
        'toLocaleTimeString' :
        'toLocaleDateString']('en-US', options);
    const formattedNextDate = nextDate ? nextDate[onlyTime ?
        'toLocaleTimeString' :
        'toLocaleDateString']('en-US', options) : 'Now';

    const tooltipBackgroundColor = '#f15223';

    const value = w.globals.series[seriesIndex][dataPointIndex];
    const formattedValue = handleThousandSeparator(
        Number.parseFloat(Number(value)?.toFixed(2))
    );
    return `<div style="background-color: ${tooltipBackgroundColor}; padding: 5px; font-weight: 600;"><strong>${datePrefix}${formattedDate} - ${formattedNextDate}</strong>: $${formattedValue}</div>`;
};

//Trade Page Other Swap Options Max toAmount
export const MaxToAmount = ({ data }) => {
    if (!data?.length) return 0;
    return data?.reduce((max, item) => {
        const toAmount = parseFloat(item?.route?.toAmount);
        return toAmount > max ? toAmount : max;
    }, 0);
};

//Trade Page Other Swap Options Max toAmount
export const MinFromAmount = ({ data }) => {
    if (!data?.length) return 0;
    return data?.reduce((min, item) => {
        const fromAmount = parseFloat(item?.route?.fromAmount);
        return fromAmount < min ? fromAmount : min;
    }, Infinity);
};
